<template>
  <v-container class="Product_bar">
    <v-row>
      <v-col cols="4">
        <h4 class="System_admin_text_Product ml-2">BLIND AD - WORKSHEET</h4>
      </v-col>

      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>DEAL: </b>
            <span
              ><b>{{ this.currentDeal }}</b></span
            ></span
          >
        </p>

        <!-- <v-text-field
                label="Record ID:"
                v-model="this.worksheetData.id"
            ></v-text-field>         -->
      </v-col>

      <v-col cols="4">
        <span  v-if="this.isValidDate(this.lastUpdate)"  class="small-lbl">
          LAST UPDATED BY: {{ this.currentUser }} on: {{ this.lastUpdate }}
          {{ this.lastUpdateTime }}
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <div class="text-right">
          <b-button-group>
            <!-- <button class="offers-button" @click="openOffers">Offers</button> -->
            <!-- <button class="tasks-button" @click="openTasks">Tasks</button> -->
            <b-button class="uploads-button" @click="openUploads"
              >Uploads</b-button
            >
            <b-button class="save-button" @click="saveFormData">Save</b-button>
            <b-button class="back-button" @click="goBack">Back</b-button>
            <WordExportButton
              templateName="BlindAdWorksheet"
              :templateData="{
                ...worksheetData,
                state: worksheetData.state_id
                  ? list_states.filter(
                      (state) => state.id === worksheetData.state_id
                    )[0].text
                  : '',
                city: worksheetData.city_id
                  ? list_city.filter(
                      (city) => city.id === worksheetData.city_id
                    )[0].text
                  : '',
              }"
            />
          </b-button-group>
        </div>
      </v-col>

      <v-col cols="3" class="small-lblhead">
        <input
          type="file"
          ref="file"
          @change="updatePreview"
          class="file-input"
          id="file-input"
        />
        <label v-if="this.worksheetData.id > 0" for="file-input">
          <i class="fa fa-folder"></i>
          &nbsp;Select a file...
        </label>

        <div class="field">
          <b-button v-if="file" class="small-lblhead" @click="saveImageFile">
            <i class="fa fa-upload"></i>
            &nbsp;Upload File</b-button
          >
        </div>
      </v-col>
    </v-row>

    <div>
      <v-container>
        <v-form v-model="valid">
          <v-row>
            <!-- Left side of page -->
            <v-col cols="6">
              <template id="Headline">
                <div>
                  <label class="small-lblhead"><b>PRIMARY HEADLINE</b></label>
                  <b-form-input
                    class="small-bold-input"
                    v-model="worksheetData.primary_headline"
                    placeholder="Primary Headline"
                    :rules="[(v) => !!v || 'This field is required']"
                  >
                  </b-form-input>
                </div>
              </template>
              <v-row>
                <v-col cols="5">
                  <template id="City">
                    <div>
                      <label class="small-lblhead"><b>CITY</b></label>
                      <v-autocomplete
                        class="small-bold-text-auto"
                        v-model="worksheetData.city_id"
                        item-text="text"
                        item-value="id" 
                        :items="list_city"
                       >
                      </v-autocomplete>
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <template id="State">
                    <div>
                      <label class="small-lblhead"><b>STATE</b></label>
                      <b-form-select
                        class="small-bold-text"
                        v-model="worksheetData.state_id"
                        placeholder="State"
                        :options="list_states"
                        value-field="id"
                        text-field="text"
                        :rules="[(v) => !!v || 'This field is required']"
                      >
                      </b-form-select>
                    </div>
                  </template>
                </v-col>
                <v-col cols="4">
                  <template id="County">
                    <div>
                      <label class="small-lblhead"><b>COUNTY</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="worksheetData.county"
                        placeholder="County"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
              </v-row>
              <v-row class="small-lblhead">
                <v-col cols="3">
                  <template>
                    <div id="Asking Price">
                      <label class="small-lblhead"><b>ASKING PRICE</b></label>
                      <money
                        v-model="worksheetData.asking_price"
                        v-bind="money"
                        class="small-bold-input-money"
                        placeholder="Asking Price"
                      ></money>
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <template id="CashFlow">
                    <div>
                      <label class="small-lblhead"><b>CASH FLOW</b></label>
                      <money
                        v-model="worksheetData.cashflow"
                        v-bind="money"
                        class="small-bold-input-money"
                      ></money>
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <template id="Gross Revenue">
                    <div>
                      <label class="small-lblhead"><b>GROSS REVENUE</b></label>
                      <money
                        v-model="worksheetData.gross_rev"
                        v-bind="money"
                        class="small-bold-input-money"
                        placeholder="Asking Price"
                      ></money>
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <template id="EBITDA">
                    <div>
                      <label class="small-lblhead"><b>EBITDA</b></label>
                      <money
                        class="small-bold-input"
                        v-model="worksheetData.ebitda"
                        v-bind="money"
                        placeholder="EBITDA"
                      >
                      </money>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead">
                <v-col cols="6">
                  <template id="EMPLOYEE COUNT">
                    <div>
                      <label class="small-lblhead"><b>EMPLOYEE COUNT</b></label>
                      <b-form-input
                        class="small-bold-input"
                        type="number"
                        v-model="worksheetData.employee_count"
                        placeholder="Employee Count"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
                <v-col cols="6">
                  <template id="Bldg SQFT">
                    <div>
                      <label class="small-lblhead"><b>BUILDING SQFT</b></label>
                      <b-form-input
                        type="number"
                        class="small-bold-input"
                        v-model="worksheetData.bldg_sq_ft"
                        placeholder="Building Sq Ft"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead">
                <v-col cols="4">
                  <template id="Inventory">
                    <div>
                      <label class="small-lblhead"><b>INVENTORY</b></label>
                      <money
                        v-model="worksheetData.inventory"
                        v-bind="money"
                        class="small-bold-input-money"
                        placeholder="Asking Price"
                      ></money>
                    </div>
                  </template>
                </v-col>
                <v-col cols="2">
                  <template id="Inv Included">
                    <div>
                      <label class="small-lblhead"><b>INCLUDED</b></label>
                      <b-form-checkbox
                        class="small-bold-input"
                        v-model="worksheetData.inv_included"
                        placeholder="Inv Included"
                      >
                      </b-form-checkbox>
                    </div>
                  </template>
                </v-col>
                <v-col cols="4">
                  <template id="FFE">
                    <div>
                      <label class="small-lblhead"><b>FF&E</b></label>
                      <money
                        v-model="worksheetData.ffe"
                        v-bind="money"
                        class="small-bold-input-money"
                        placeholder="Asking Price"
                      ></money>
                    </div>
                  </template>
                </v-col>
                <v-col cols="2">
                  <template id="FFE Included">
                    <div>
                      <label class="small-lblhead"><b>INCLUDED</b></label>
                      <b-form-checkbox
                        class="small-bold-input"
                        v-model="worksheetData.ffe_included"
                        placeholder="FFE Included"
                      >
                      </b-form-checkbox>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead">
                <v-col cols="4">
                  <template id="Real Estate">
                    <div>
                      <label class="small-lblhead"><b>REAL ESTATE</b></label>
                      <money
                        v-model="worksheetData.real_value"
                        v-bind="money"
                        class="small-bold-input-money"
                        placeholder="Asking Price"
                      ></money>
                    </div>
                  </template>
                </v-col>
                <v-col cols="2">
                  <template id="Real Included">
                    <div>
                      <label class="small-lblhead"><b>INCLUDED</b></label>
                      <b-form-checkbox
                        class="small-bold-input"
                        v-model="worksheetData.real_included"
                        placeholder="Real Included"
                      >
                      </b-form-checkbox>
                    </div>
                  </template>
                </v-col>
                <v-col cols="6">
                  <template id="Year Started">
                    <div>
                      <label class="small-lblhead"><b>YEAR STARTED</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="worksheetData.year_established"
                        placeholder="Year Started"
                        type="number"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead">
                <v-col cols="6">
                  <template id="HEADLINE #2">
                    <div>
                      <label class="small-lblhead"><b>HEADLINE #2</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="worksheetData.headline2"
                        placeholder="Headline #2"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
                <v-col cols="6">
                  <template id="Reason to Sell">
                    <div>
                      <label class="small-lblhead"><b>REASON TO SELL</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="worksheetData.sell_reasons"
                        placeholder="Reason to Sell"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead">
                <v-col cols="12">
                  <template id="Facilities Paragraph">
                    <div>
                      <label class="small-lblhead"
                        ><b>FACILITIES PARAGRAPH</b></label
                      >
                      <b-form-textarea
                        class="small-bold-text"
                        v-model="worksheetData.facilities_text"
                        placeholder="Facilities Paragraph"
                        rows="3"
                        max-rows="8"
                      >
                      </b-form-textarea>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead">
                <v-col cols="12">
                  <template id="Growth & Expansion">
                    <div>
                      <label class="small-lblhead"
                        ><b>GROWTH & EXPANSION PARAGRAPH</b></label
                      >
                      <b-form-textarea
                        class="small-bold-text"
                        v-model="worksheetData.growth_exp"
                        placeholder="Growth & Expansion"
                        rows="3"
                        max-rows="10"
                      >
                      </b-form-textarea>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-col>
            <!-- Right side of page bearing image -->
            <v-col cols="6" id="Right Side Columns">
              <v-col cols="12">
                <template id="Primary Image File">
                  <div>
                    <label class="small-lblhead"
                      ><b>PRIMARY IMAGE FILE</b></label
                    >
                    <b-form-input
                      class="small-bold-input"
                      v-model="worksheetData.file_name"
                      placeholder="Image File"
                    >
                    </b-form-input>
                  </div>
                </template>
              </v-col>
              <v-col cols="12">
                <form
                  @submit.prevent="sendFile"
                  enctype="multipart/form-data"
                  v-if="imagePreview"
                >
                  <v-row>
                    <div class="user-profile">
                      <v-img
                        :src="imagePreview"
                        contain
                        height="298"
                        class="preview-image"
                        @click="
                          downloadFile(
                            worksheetData.file,
                            worksheetData.filename
                          )
                        "
                        outlined
                      >
                      </v-img>
                    </div>
                  </v-row>
                </form>
              </v-col>

              <v-col cols="12">
                <template id="Competition Paragraph">
                  <div>
                    <label class="small-lblhead"
                      ><b>COMPETITION PARAGRAPH</b></label
                    >
                    <b-form-textarea
                      class="small-bold-text"
                      v-model="worksheetData.competition_text"
                      placeholder="Competion Paragraph"
                      rows="3"
                      max-rows="10"
                    >
                    </b-form-textarea>
                  </div>
                </template>
              </v-col>

              <v-col cols="12">
                <template id="Support & Training">
                  <div>
                    <label class="small-lblhead"
                      ><b>SUPPORT & TRAINING PARAGRAPH</b></label
                    >
                    <b-form-textarea
                      class="small-bold-text"
                      v-model="worksheetData.supp_training"
                      placeholder="Support & Training Paragraph"
                      rows="3"
                      max-rows="10"
                    >
                    </b-form-textarea>
                  </div>
                </template>
              </v-col>
            </v-col>
          </v-row>

          <!-- Large text block spanning across the bottom both sides                        -->
          <v-row>
            <v-col cols="12">
              <v-row>
                <template id="Large Text Block">
                  <div>
                    <label class="small-lblhead"><b>LARGE TEXT BLOCK</b></label>
                    <b-form-textarea
                      class="small-bold-text"
                      v-model="worksheetData.detail_text"
                      placeholder="Large Text Block"
                      rows="3"
                      max-rows="100"
                    >
                    </b-form-textarea>
                  </div>
                </template>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row>
                <template id="Summary Text Block">
                  <div>
                    <label class="small-lblhead"
                      ><b>SUMMARY TEXT BLOCK</b></label
                    >
                    <b-form-textarea
                      class="small-bold-text"
                      v-model="worksheetData.summary_text"
                      placeholder="Summary Text Block"
                      rows="3"
                      max-rows="100"
                    >
                    </b-form-textarea>
                  </div>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>

    <v-dialog v-model="dialogEdit" max-width="600" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeEdit">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>{{ worksheetData.id ? "Update Item" : "Add Item" }}</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>

                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="saveFormData">
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import WordExportButton from "./WordExportButton.vue";
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";
import axios from "axios";
import { mask } from "vue-the-mask";
import { Money } from "v-money";

function formatAsCurrency(value, dec) {
  dec = dec || 0;
  if (value === null) {
    return 0;
  }
  return "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export default {
  components: { WordExportButton, Money },
  directives: {
    mask,
  },
  computed: {
    askingPriceFormatted: {
      get: function () {
        return formatAsCurrency(this.worksheetData.asking_price, 0);
      },
      set: function (newValue) {
        this.worksheetData.asking_price = Number(
          newValue.replace(/[^0-9.]/g, "")
        );
      },
    },
  },
  async created() {
    this.checkPermissions();
    this.logActivity("Opened", localStorage.getItem("DealID"));
    // fetch cities
    CommonServices.getList("Cities")
      .then((res) => {
        let codes = res.results;
        codes.map((code) =>
          this.list_city.push({ id: code.id, text: code.city })
        );
      })
      .catch((err) => {
        console.error(err);
      });
    // fetch states
    CommonServices.getList("States")
      .then((res) => {
        let codes = res.results;
        codes.map((code) =>
          this.list_states.push({ id: code.id, text: code.state })
        );
      })
      .catch((err) => {
        console.error(err);
      });
    try {
      // fetch blind ad id
      let res = await CommonServices.getList(this.componentURL);
      res = res.results[res.results.length - 1];
      this.worksheetData = {
        ...res,
        asking_price: res.asking_price ? Number(res.asking_price) : 0,
        cashflow: res.cashflow ? Number(res.cashflow) : 0,
        gross_rev: res.gross_rev ? Number(res.gross_rev) : 0,
        real_value: res.real_value ? Number(res.real_value) : 0,
        ffe: res.ffe ? Number(res.ffe) : 0,
        inventory: res.inventory ? Number(res.inventory) : 0,
      };
      this.imagePreview = this.worksheetData.blindad_photo_file;
      localStorage.setItem("lastupdate", this.worksheetData.last_updated);
      this.lastUpdate = this.worksheetData.last_updated;
      console.log("Worksheet Data:", this.worksheetData);
    } catch (err) {
      console.error(err);
    }
    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  },
  data() {
    return {
      componentURL: `BlindAd/?deal_id=${this.$route.params.id}`,
      componentURL_base: `BlindAd`,
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      currentDeal: localStorage.getItem("Deal"),
      currentUser:
        localStorage.getItem("first_name") +
        " " +
        localStorage.getItem("last_name"),
      lastUpdate: this.$moment(
        String(localStorage.getItem("LastUpdate"))
      ).format("MM-DD-YYYY"),
      lastUpdateTime: localStorage.getItem("LastUpdateTime"),

      currency: "$",

      file: "",
      fileURL: "/",
      imagePreview: "",

      word: "",
      usCurrency: null,

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: " ",
        precision: 0,
        masked: false,
      },

      items: [],

      list_user: [],
      list_city: [],
      list_states: [],

      //worksheetData: [],
      worksheetData: {
        id: "",
        deal_id: this.$route.params.id,
        deal: "",
        file_name: "",
        upload_file_name: "",
        file: null,
        last_upload_date: this.$moment(String(Date())).format("MM/DD/YYYY"),
        last_update_user_id: "",
        primary_headline: "",
        city_id: "",
        state_id: "",
        county: "",
        cover_image_path: "",
        asking_price: null,
        cashflow: null,
        gross_rev: null,
        ebitda: "",
        year_established: null,
        ffe: null,
        ffe_included: false,
        inventory: null,
        inv_included: false,
        real_value: null,
        real_included: false,
        reowned: "",
        headline2: "",
        detail_text: "",
        bldg_sq_ft: null,
        employee_count: null,
        facilities_text: "",
        sell_reasons: "",
        timestamp: "",
        created: "",
        last_updated: null,
      },

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "BlindAdWorksheet",
        action: "",
        deal_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        currency: "$###,###,###.00",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    localStorage.setItem("lastupdate", this.worksheetData.last_updated);
  },
  methods: {
    checkPermissions() {
      if (this.role >3) this.$router.push("/");
    },

    isValidDate(sDate){
      const parsedDate = new Date(sDate);
      return !isNaN(parsedDate.getTime());

    },

    async logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      await CommonServices.postData("UserActivityLog", this.userActivityItem);
    },

    async saveImageFile() {
      //This will upload only the BlindAd's photo to the BlindAdPhotos table
      try {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("upload_file_name", this.file.name);
        formData.append("blindad_id", this.$route.params.id);
        await CommonServices.postData("/BlindAdPhotos", formData).then(
          (res) => {
            this.imagePreview = res.file;
            console.log("PhotoID", res.id);
            CommonServices.putData("BlindAd", {
              id: this.worksheetData.id,
              blindad_photo_id: res.id,
              last_updated: this.$moment(String(Date())).format(
                "YYYY-MM-DDThh:mm"
              ),
            }).then((response) => console.log("Post Image Response", response));
            this.worksheetData.blindad_photo_id = res.id;
            //location.reload();
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Photo successfully updated";
            this.dialog = false;
            this.logActivity(
              "Uploaded BlindAd Photo",
              "",
              JSON.stringify(this.worksheetData)
            );
          }
        );
      } catch (error) {
        this.snackbar = true;
        this.snackColor = "error";
        try {
          Object.keys(error.response.data).forEach((key) => {
            this.snackText = error.response.data[key][0];
          });
        } catch (e) {
          this.snackText = "Something went wrong";
        }
      }
    },

    openEdit() {
      this.worksheetData.length = 0;
      this.dialogEdit = true;
    },

    openTasks() {
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openUploads() {
      //First write any missing Deal Folders to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then(() => {
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.$route.params.id}`);
          }, 500);
        })
        .catch((error) => {
          console.error("Put Function Error: ", error);
        });
    },

    downloadFile(url, filename) {
      CommonServices.downloadFile(url, filename);
    },

    displayFile(item) {
      this.worksheetData.file_name = item.title;
    },

    selectFile() {
      this.file = this.$refs.file.files[0];
      this.updatePreview();
    },

    async sendFile() {
      try {
        if (this.worksheetData.bldg_sq_ft == "") {
          this.worksheetData.bldg_sq_ft = null;
        }
        if (this.worksheetData.state_id == "") {
          this.worksheetData.state_id = null;
        }
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("file_name", this.worksheetData.file_name);
        formData.append("upload_file_name", this.file.name);
        formData.append("deal_id", this.worksheetData.deal_id);
        formData.append("last_update_user_id", localStorage.getItem("userid"));
        formData.append(
          "primary_headline",
          this.worksheetData.primary_headline
        );
        formData.append("county", this.worksheetData.county);
        formData.append(
          "cover_image_path",
          this.worksheetData.cover_image_path
        );
        formData.append("ebitda", this.worksheetData.ebitda);
        formData.append("ffe_included", this.worksheetData.ffe_included);
        formData.append("inv_included", this.worksheetData.inv_included);
        formData.append("real_included", this.worksheetData.real_included);
        formData.append("reowned", this.worksheetData.reowned);
        formData.append("headline2", this.worksheetData.headline2);
        formData.append("detail_text", this.worksheetData.detail_text);
        formData.append("facilities_text", this.worksheetData.facilities_text);
        formData.append(
          "competition_text",
          this.worksheetData.competition_text
        );
        formData.append("growth_exp", this.worksheetData.growth_exp);
        formData.append("supp_training", this.worksheetData.supp_training);
        formData.append("sell_reasons", this.worksheetData.sell_reasons);
        formData.append("timestamp", this.worksheetData.created);
        formData.append("created", this.worksheetData.created);
        formData.append(
          "last_updated",
          this.$moment(String(Date())).format("YYYY-MM-DDThh:mm")
        );
        const fieldList = [
          "state_id",
          "city_id",
          "asking_price",
          "cashflow",
          "gross_rev",
          "year_established",
          "ffe",
          "inventory",
          "real_value",
          "bldg_sq_ft",
          "employee_count",
        ];
        fieldList.forEach((field) => {
          if (!this.worksheetData[field]) return;
          formData.append(field, this.worksheetData[field]);
        });
        await axios.post("/BlindAd/", formData).then((res) => {
          this.fileURL = res.data.file;
          this.worksheetData.file = this.fileURL;
          this.deleteItemSilently();
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "File successfully updated";
          this.dialog = false;
          this.logActivity(
            "Uploaded Photo",
            this.worksheetData.deal_id,
            JSON.stringify(this.worksheetData)
          );
        });
      } catch (error) {
        this.snackbar = true;
        this.snackColor = "error";
        try {
          Object.keys(error.response.data).forEach((key) => {
            this.snackText = error.response.data[key][0];
          });
        } catch (e) {
          this.snackText = "Something went wrong";
        }
      }
    },

    openUpload() {
      document.getElementById("file-input").click();
    },

    updatePreview(e) {
      var reader,
        files = e.target.files;

      reader = new FileReader();

      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };

      reader.readAsDataURL(files[0]);

      this.file = this.$refs.file.files[0];
      this.worksheetData.file_name = this.file.name;
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "User Folder Permissions"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Folder Permissions.xlsx");
    },

    saveEditDialog(item) {
      CommonServices.updateData(this.componentURL, item).then((response) => {
        const index = this.items.findIndex((item) => item.id === response.id);
        this.$set(this.items, index, response);
      });
      this.snackbar = true;
      this.snackColor = "success";
      this.snackText = "Record successfully updated";
    },

    saveFormData() {
      if(!this.worksheetData.primary_headline){
        window.alert('You must enter a Primary Headline before saving the record.')
        return 
      }
      console.log("Saving form..id:", this.worksheetData.id);
      if (this.worksheetData.last_updated) {
        this.worksheetData.last_updated = this.$moment(
          String(this.worksheetData.last_updated)
        ).format("YYYY-MM-DDThh:mm");
      } else {
        this.worksheetData.last_updated = this.$moment(String(Date())).format(
          "YYYY-MM-DDThh:mm"
        );
      }
      if (this.worksheetData.id) {
        CommonServices.updateData(this.componentURL_base, this.worksheetData)
          .then((response) => {
            const index = this.items.findIndex(
              (item) => item.id === response.id
            );
            this.$set(this.items, index, response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
            this.logActivity(
              "Saved Form Data",
              this.worksheetData.deal_id,
              JSON.stringify(this.worksheetData)
            );
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        this.worksheetData.deal_id = this.$route.params.id;
        CommonServices.addRecord(this.componentURL, this.worksheetData)
          .then((response) => {
            this.items.push(response);
            console.log("Response ID:", response.id);
            this.worksheetData.id = response.id;
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      CommonServices.deleteRecord(this.componentURL_base, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          this.logActivity(
            "Deleted Record",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.error(error);
        });
      this.closeDelete();
    },

    deleteItemSilently() {
      CommonServices.deleteRecord(this.componentURL_base, this.worksheetData.id)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.getData();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    worksheetDatas(item) {
      this.worksheetData = Object.assign({}, item);
      localStorage.setItem("DealID", item.deal_id);
      this.dialog = true;
    },

    clearSearch() {
      this.searchText = "";
      this.getData();
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.worksheetData = Object.assign({}, this.worksheetData);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.$refs.form.reset();
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.worksheetData = Object.assign({}, this.worksheetData);
        this.editedIndex = -1;
      });
    },
    goBack() {
      return this.$router.go(-1);
    },

    Export2Doc(element, filename = "") {
      //  _html_ will be replace with custom html
      var meta =
        "Mime-Version: 1.0\nContent-Base: " +
        location.href +
        '\nContent-Type: Multipart/related; boundary="NEXT.ITEM-BOUNDARY";type="text/html"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset="utf-8"\nContent-Location: ' +
        location.href +
        "\n\n<!DOCTYPE html>\n<html>\n_html_</html>";
      //  _styles_ will be replaced with custome css
      var head =
        '<head>\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8">\n<style>\n_styles_\n</style>\n</head>\n';

      var html = document.getElementById(element).innerHTML;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });

      var css =
        "<style>" +
        "img {width:300px;}table {border-collapse: collapse; border-spacing: 0;}td{padding: 6px;}" +
        "</style>";
      //  Image Area %%%%
      var options = { maxWidth: 624 };
      var images = Array();
      var img = ("#" + element).find("img");
      for (var i = 0; i < img.length; i++) {
        // Calculate dimensions of output image
        var w = Math.min(img[i].width, options.maxWidth);
        var h = img[i].height * (w / img[i].width);
        // Create canvas for converting image to data URL
        var canvas = document.createElement("CANVAS");
        canvas.width = w;
        canvas.height = h;
        // Draw image to canvas
        var context = canvas.getContext("2d");
        context.drawImage(img[i], 0, 0, w, h);
        // Get data URL encoding of image
        var uri = canvas.toDataURL("image/png");
        img[i].attr("src", img[i].src);
        img[i].width = w;
        img[i].height = h;
        // Save encoded image to array
        images[i] = {
          type: uri.substring(uri.indexOf(":") + 1, uri.indexOf(";")),
          encoding: uri.substring(uri.indexOf(";") + 1, uri.indexOf(",")),
          location: img[i].attr("src"),
          data: uri.substring(uri.indexOf(",") + 1),
        };
      }

      // Prepare bottom of mhtml file with image data
      var imgMetaData = "\n";
      for (i = 0; i < images.length; i++) {
        imgMetaData += "--NEXT.ITEM-BOUNDARY\n";
        imgMetaData += "Content-Location: " + images[i].location + "\n";
        imgMetaData += "Content-Type: " + images[i].type + "\n";
        imgMetaData +=
          "Content-Transfer-Encoding: " + images[i].encoding + "\n\n";
        imgMetaData += images[i].data + "\n\n";
      }
      imgMetaData += "--NEXT.ITEM-BOUNDARY--";
      // end Image Area %%

      var output =
        meta.replace("_html_", head.replace("_styles_", css) + html) +
        imgMetaData;

      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(output);

      filename = filename ? filename + ".doc" : "document.doc";

      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    },

    exportHTML() {
      var vm = this,
        word = `<html xmlns:o='urn:schemas-microsoft-com:office:office xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>${vm.word}</body></html>`;

      var source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(word);
      var fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = "hsl-file.doc";
      fileDownload.click();
      document.body.removeChild(fileDownload);
    },
  },
};
</script>

<style src="../css/styles.css" scoped></style>
